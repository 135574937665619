import { Component, OnInit, Inject, ViewChild, ElementRef} from "@angular/core";
import { PacienteService } from "../_services/paciente.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: "app-paciente-bio",
  templateUrl: "./paciente-bio.component.html",
  styleUrls: ["./paciente-bio.component.css"],
})
export class PacienteBioComponent implements OnInit {
  currentPatient: any;
  patientData: any;
  hayMuchasCoberturas: boolean;

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(
    private pacienteService: PacienteService,
    public dialogRef: MatDialogRef<PacienteBioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  ngOnInit() {
    this.currentPatient = this.pacienteService.getCurrentPatient(); 
    this.loadPatient();
  }

  loadPatient(){
    if (this.currentPatient) {
      this.pacienteService
        .datosPaciente(
          this.currentPatient.numeroDoc,
          this.currentPatient.tipoDoc
        )
        .subscribe(
          (data) => {
            this.patientData = data;
            this.hayMuchasCoberturas = this.patientData.coberturas.length > 4;
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  scrollDown() {
    const container = this.scrollContainer.nativeElement;
      container.scrollBy({
        top: 200,
        left: 0,
        behavior: 'smooth',
      });
    }
    scrollUp() {
      const container = this.scrollContainer.nativeElement;
        container.scrollBy({
          top: -200,
          left: 0,
          behavior: 'smooth',
        });
      }
}
