<div class="container-fluid" *ngIf="cargado" style=" min-height: 100%; max-height: auto;">
    <div class="container-fluid" *ngIf="(this.soySecretaria && this.respuestaProfesionales.profesionales.length != 0) || !soySecretaria; else elseBlockTemplate" style="width: 100%; min-height: 100%; max-height: auto;">
        <div class="row d-flex justify-content-center" style="min-height: 100%; max-height: auto;"
             *ngIf="this.tieneSucursales; else elseBlockTemplateAgendasVigentes">
            <div class="col-3 col-sm-4 col-md-4 col-lg-3 col-xl-3 " style="padding-top: 2%;" >
                <div class="row d-flex justify-content-center">
                    <h5 class="text-center" style="width:100%">Establecimiento</h5>
                    <mat-form-field>
                        <mat-label>Seleccione el establecimiento</mat-label>
                        <mat-select (selectionChange)="seleccionoSucursal($event.value)" [ngModel]="sucursalSeleccionada">
                            <mat-option *ngFor="let sucursal of listaSucursales" [value]="sucursal.id">
                                {{ sucursal.nombre }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="mt-2 row d-flex justify-content-center" *ngIf="this.soySecretaria">
                    <h5 class="text-center" style="width:100%">Profesional</h5>
                    <mat-form-field>
                        <mat-label>Seleccione el profesional</mat-label>
                        <mat-select (selectionChange)="seleccionoProfesional($event.value)" [ngModel]="profesionalSeleccionado">
                            <mat-option *ngFor="let profesional of respuestaProfesionales.profesionales" [value]="profesional.matricula">
                                {{ profesional.nombreCompleto }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="mt-2 row d-flex justify-content-center">
                    <h5 class="text-center" style="width:100%">Consultorio</h5>
                    <mat-form-field>
                        <mat-label>Seleccione el consultorio</mat-label>
                        <mat-select (selectionChange)="seleccionoConsultorio($event.value)" [ngModel]="consultorioActual">
                            <mat-option *ngFor="let consultorio of listaConsultorios" [value]="consultorio">
                                Nro {{consultorio.descripcion}} - {{consultorio.ubicacion}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="row justify-content-md-center d-flex justify-content-center">

                    <button mat-raised-button color="primary" (click)="openDialogPacientes()" style="border-radius: 20px;">
                    <mat-icon >search</mat-icon>
                    Buscar paciente
                    </button>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7" style="text-align: center; height: 100%; padding-top: 2%;">

                <div class="d-flex justify-content-around flex-nowrap">
                    <button matTooltip="Horario anterior" mat-mini-fab color="primary" (click)="bloqueAnterior()">
                        <mat-icon>navigate_before</mat-icon>
                    </button>

                    <mat-form-field>
                        <input matInput [(ngModel)]="fechaSeleccionada" placeholder="Seleccione una fecha" [matDatepicker]="picker" (dateChange)="onDateChange($event)" />
                        <mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Seleccionar fecha"></mat-datepicker-toggle>
                        <mat-datepicker-toggle matSuffix (click)="seleccionarDiaDeHoy()" matTooltip="Hoy">
                            <mat-icon matDatepickerToggleIcon>event_available</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                    </mat-form-field>

                    <div class="d-flex flex-wrap">
                        <button matTooltip="Horario siguiente" mat-mini-fab color="primary" class="mr-2" (click)="bloqueSiguiente()">
                      <mat-icon>navigate_next</mat-icon>
                    </button>
                        <!-- <button matTooltip="Horario con proximo turno libre" mat-mini-fab color="primary" (click)="bloqueTurnoLibre()">
                      <mat-icon>last_page</mat-icon>
                    </button> -->
                    </div>
                </div>

                <div class="row justify-content-md-center d-flex justify-content-center">
                    <div style="margin-top: 10%" *ngIf="loading">
                        <mat-spinner [diameter]="60"></mat-spinner>
                    </div>
                </div>

                <div class="row" style="height: 100%;">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="!loading" style="height: 100%;">
                        <div class="d-flex justify-content-center" *ngIf="respuestaTurnos.turnos && respuestaTurnos.turnos.length == 0; else mostrarTabla">
                            <h6>No hay turnos para esta fecha </h6>
                        </div>
                        <ng-template #mostrarTabla>
                            <div *ngIf="respuestaTurnos.turnos && respuestaTurnos.turnos.length > 0" class="d-flex justify-content-center" style="padding-top: 1%;">
                                <h6 class="my-auto">
                                    <span *ngIf="fechaSeleccionada">{{ getDay(fechaSeleccionada) }}</span> de {{ this.horaIni }} a {{ this.horaFin }} - {{ this.nombreConsultorio }}
                                </h6>

                                <div class="ml-2 my-auto">
                                    <button mat-icon-button color="primary" (click)="refrescarTurnos()" matTooltip="Refrescar turnos"><mat-icon>refresh</mat-icon></button>
                                    <button mat-icon-button color="warn" (click)="cancelarTurnos()" *ngIf="!this.respuestaTurnos.tieneCancelaciones && this.sucursalSeleccionada != 1" matTooltip="Suspender turnos"><mat-icon>cancel</mat-icon></button>
                                    <button mat-icon-button (click)="levantarcancelacionesTurnos()" *ngIf="this.respuestaTurnos.tieneCancelaciones && this.sucursalSeleccionada != 1" matTooltip="Activar turnos"><mat-icon [ngStyle]="{'color':'green'}">check_circle</mat-icon></button>

                                </div>
                                <div class="col-sm-3">
                                    <a href=" https://profesionales.hospitalespanol.org.ar/he_fe/#/login?site=ver_turno_como_profesional" target="_blank" (click)="registrarEstadistica()">Volver a la versión anterior de la agenda</a>
                                </div>
                            </div>

                            <div class="d-flex justify-content-center" *ngIf="btnVolver">
                                <button mat-stroked-button color="primary" (click)="onVolver()" style="border-radius: 20px;">
                                    <mat-icon>skip_previous</mat-icon>Volver a Búsqueda de turnos
                                </button>
                            </div>

                            <hr>
                            <table mat-table [dataSource]="turno" class="mat-elevation-z8 mb-4 mt-4">

                                <!-- Columna horario -->
                                <ng-container matColumnDef="horario">
                                    <th mat-header-cell *matHeaderCellDef class="text-center"> Horario </th>
                                    <td mat-cell *matCellDef="let element"> {{element.str_hora}} </td>
                                </ng-container>

                                <!-- Columna numero turno -->
                                <ng-container matColumnDef="numero">
                                    <th mat-header-cell *matHeaderCellDef class="text-center"> Turno </th>
                                    <td mat-cell *matCellDef="let element"> {{element.numero}} </td>
                                </ng-container>

                                <!-- Columna paciente -->
                                <ng-container matColumnDef="paciente">
                                    <th mat-header-cell *matHeaderCellDef class="text-center"> Paciente </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div *ngIf="element.usuarioTomo; else TurnoSinTomarTemplate">
                                            <span (click)="element.str_paciente != 'cancelo medico' && seleccionarPaciente(element)" style="cursor: pointer; font-size: 16px;">{{ element.str_paciente | toTitleCase }}</span>
                                            <span class="warning timeWaiting" *ngIf="element.estado.getCodigo() == 8 && itsToday()"> - {{element.timeWaiting()}}</span>
                                            <span *ngIf="element.estudios" style="display: block;"><strong>Estudios: </strong>{{ element.estudios | toTitleCase }}</span>
                                        </div>
                                        <ng-template #TurnoSinTomarTemplate>
                                            <div *ngIf="!pastTimeSpecific(element)">Turno libre</div>
                                            <div *ngIf="pastTimeSpecific(element)">No disponible</div>
                                        </ng-template>
                                    </td>
                                </ng-container>
                                 <!-- columna color triage -->
                                
                                <ng-container matColumnDef="triage"  >
                                   
                                    <th mat-header-cell *matHeaderCellDef class="text-center" [hidden]="this.servicioId != 169"> Triage </th>
                                    <td mat-cell *matCellDef ="let triage " [hidden]="this.servicioId != 169"> 
                                        <div *ngIf="triage.colorTriage == '3'" >
                                            <mat-icon style="-webkit-text-fill-color:green;">lens</mat-icon>
                                        </div>
                                        <div *ngIf="triage.colorTriage == '2'" >
                                            <mat-icon style="-webkit-text-fill-color:yellow;">lens</mat-icon>
                                        </div>
                                        <div *ngIf="triage.colorTriage == '1'" >
                                            <mat-icon style="-webkit-text-fill-color:red;">lens</mat-icon>
                                        </div>
                                    </td>
                                
                                </ng-container>
                                <ng-container matColumnDef="estado">
                                    <th mat-header-cell *matHeaderCellDef class="text-center"> Estado </th>
                                    <td mat-cell *matCellDef="let turno">
                                        <div *ngIf='turno.usuarioTomo'>
                                            <div [ngSwitch]="turno.estado.getCodigo()">
                                                <div *ngSwitchCase="'8'" class="text-danger my-auto">
                                                    Esperando
                                                </div>
                                                <div *ngSwitchCase="'2'" class="text-primary my-auto">
                                                    En atencion
                                                </div>
                                                <div *ngSwitchCase="'3'" class="text-success my-auto">
                                                    Atendido
                                                </div>
                                                <div *ngSwitchCase="'9'" class="text-primary my-auto">
                                                    Llamando
                                                </div>
                                                <div *ngSwitchCase="'1'" class="text-warning my-auto">
                                                    En estudios
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="acciones">
                                    <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                    <td mat-cell *matCellDef="let turno">

                                        <div *ngIf="turno.usuarioTomo; else TurnoNoTomadoTemplate">
                                            <div *ngIf="turno.str_paciente != 'cancelo medico'">

                                                <button matTooltip="Registrar llegada del paciente a la sucursal" *ngIf="this.sucursalSeleccionada != 1 && itsToday() && turno.str_paciente != 'cancelo medico' && turno.estado.getCodigo() == 'N'; else elseBlockTemporal" (click)="analizarPaciente(turno)"
                                                    mat-icon-button color="primary">
                                  <!--<mat-icon class="iconAng mr-1">call_received</mat-icon>-->
                                                    <img id="iconImg" src="../../assets/images/iconos_atender/icono_ir_turno_prox.png" />
                                                </button>
                                                <ng-template #elseBlockTemporal>
                                                    <button matTooltip="Confirmar datos del paciente" *ngIf="this.sucursalSeleccionada != 1 && itsToday() && turno.str_paciente != 'cancelo medico' && (turno.tipoDoc== 9 || turno.tipoDoc=='9')" (click)="registrarTemporal(turno)" mat-icon-button color="primary">
                                         <!--<mat-icon class="iconAng mr-1">call_received</mat-icon>-->
                                                        <img id="iconImg" src="../../assets/images/iconos_atender/icono_triage.png" />
                                                    </button>
                                                </ng-template>
                                    <span (click)="llamarTv(turno)" title="Llamar(tv)" class="manitas" *ngIf="((!pastTime() && turno.estado.getCodigo() == '8') || turno.estado.getCodigo() == '1') && !this.perfilSecretaria()">
                                        <img id="iconImg" src="../../assets/images/iconos_atender/icono_llamar_paciente.png" />
                                    </span>

                                    <span #stepper (click)="goForward(stepper, turno)" title="Registrar llegada del paciente al consultorio" class="manitas" *ngIf="((turno.estado.getCodigo() == '8' || turno.estado.getCodigo() == '9' || turno.estado.getCodigo() == '1') && servicioId != 169) && !this.perfilSecretaria()">
                                        <img id="iconImg" src="../../assets/images/iconos_atender/icono_paciente_atend_2.png" />
                                    </span>

                                    <span #stepper (click)="analizarAtencion(stepper, turno)" title="Registrar llegada del paciente al consultorio" class="manitas" *ngIf="((turno.estado.getCodigo() == '8' || turno.estado.getCodigo() == '9' || turno.estado.getCodigo() == '1') && servicioId == 169) && !this.perfilSecretaria()">
                                        <img id="iconImg" src="../../assets/images/iconos_atender/icono_paciente_atend_2.png" />
                                    </span>

                                                <span #stepper (click)="openDialogReciboAphe(turno)" title="Datos del Paciente" class="manitas">
                                    <img id="iconImg" src="../../assets/images/iconos_paciente/icono_ver.png" />
                                  </span>

                                                <span (click)="seleccionarPaciente(turno)" title="Historial Clinico" class="manitas">
                                  <img id="iconImg" src="../../assets/images/iconos_paciente/accion_hist_clinico.png" />
                                </span>


                                <span (click)="cancelarLLamarTv(turno)" *ngIf="
                                        turno.estado.mostrarTv() &&
                                        this.tvBloqueado &&
                                        turno.llamadoTv
                                      " title="Cancelar llamada(tv)" class="manitas">
                            <img id="iconImg" src="../../assets/images/iconos_atender/icono_llamar_paciente_cancel_2.png" />
                          </span>

                          <span #stepper (click)="imprimirRP(turno)" title="Ver r/p" class="manitas" *ngIf="turno.numeroPedido != 0">
                            <img id="iconImg" src="../../assets/images/iconos_atender/icono_pdf.png" />
                          </span>
                                                <span *ngIf="!pastTime()">
                            

                            <span #stepper (click)="goForward(stepper, turno)" title="Marcar como atendido" class="manitas" *ngIf="turno.estado.getCodigo() == 2 && servicioId != 169">
                              <img id="iconImg" src="../../assets/images/iconos_atender/icono_paciente_fue_atend.png" />
                            </span>

                            <span #stepper (click)="openDialogAtencion(stepper, turno, false)" title="Marcar como atendido" class="manitas" *ngIf="turno.estado.getCodigo() == 2 && servicioId == 169">
                                <img id="iconImg" src="../../assets/images/iconos_atender/icono_paciente_fue_atend.png" />
                            </span>

                            <span #stepper (click)="goBack(stepper, turno)" title="Cancelar fin atencion" class="manitas" *ngIf="turno.estado.getCodigo() == 3">
                                <img id="iconImg" src="../../assets/images/iconos_atender/icono_paciente_cancelar.png" />
                            </span>
                            <span #stepper (click)="goBack(stepper, turno)" title="Cancelar registro llegada" class="manitas" *ngIf="turno.estado.getCodigo() == 2 && !this.perfilSecretaria()">
                                <img id="iconImg" src="../../assets/images/iconos_atender/icono_paciente_atend_cancel_2.png" />
                            </span>


                                                <button class="warning" matTooltip="Cancelar turno" *ngIf="
                                            !(
                                              turno.estado.getCodigo() == 3 ||
                                              turno.estado.getCodigo() == 2 ||
                                              turno.estado.getCodigo() == '8' ||
                                              turno.estado.getCodigo() == '9' ||
                                              turno.estado.getCodigo() == '1'
                                            )
                                          " mat-icon-button (click)="cancelarTurno(turno)">
                              <!--<mat-icon class="iconAng">cancel</mat-icon>-->
                              <img id="iconImg" src="../../assets/images/iconos_atender/icono_reservar_turno_rojo.png" />
                            </button>
                                                </span>
                                            </div>

                                        </div>
                                        <ng-template #TurnoNoTomadoTemplate>
                                            <span #stepper (click)="openDialog(turno)" title="Reservar turno" class="manitas" *ngIf="(!pastTimeSpecific(turno) && habilitadoTurnoweb(turno)) || !pastTimeSpecific(turno)">
                                                <img id="iconImg" src="../../assets/images/iconos_atender/icono_reservar_turno.png"/>
                                            </span>
                                            <!--<span #stepper (click)="openDialog(turno)" title="Reservar turno" class="manitas" *ngIf="(!pastTimeSpecific(turno) && habilitadoTurnoweb(turno))">
                                                <img id="iconImg" src="../../assets/images/iconos_atender/icono_reservar_turno.png"/>
                                            </span>-->
                                        </ng-template>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </ng-template>
                        <span (click)="tomarTurnoExtra()">
                            <button mat-raised-button color="primary" style="border-radius: 20px; margin-bottom: 20px;">
                                <mat-icon>add</mat-icon>
                                Agregar sobreturno
                            </button>
                        </span>
                    </div>
                </div>

            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>
        </div>
        <ng-template #elseBlockTemplateAgendasVigentes>
            <mat-card class="sin-elementos-card">
                <h5>{{mensajeRespuesta}}</h5>
                <h6>Oprima el botón de abajo si está buscando un paciente</h6>
            </mat-card>
            <div class="row justify-content-md-center d-flex justify-content-center" *ngIf="!this.soySecretaria">
                <button mat-raised-button color="primary" (click)="openDialogPacientes()" style="border-radius: 20px;">
                <mat-icon >search</mat-icon>
                Buscar paciente
                </button>
            </div>
        </ng-template>

    </div>
    <ng-template #elseBlockTemplate>
        <div class="d-flex justify-content-center">
            <h6 class="text-center my-5">
                El establecimiento del cual es secretaria no posee profesionales
            </h6>
        </div>
    </ng-template>
</div>