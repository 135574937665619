  <div #scrollContainer class="scroll-container" *ngIf="this.patientData">
    <mat-list dense role="list">
      <div class="mat-list-title">Contacto</div>
      <mat-list-item role="listitem">Teléfono: {{this.patientData.telefono}} </mat-list-item>
      <mat-list-item role="listitem">Domicilio: {{this.patientData.domicilio}} </mat-list-item>
      <mat-list-item role="listitem">Localidad: {{this.patientData.localidad | toTitleCase}} </mat-list-item>
      <mat-list-item role="listitem">Mail: {{this.patientData.email | toTitleCase}} </mat-list-item>

      <div class="mat-list-title">Mutuales</div>
      <mat-list-item  role="listitem" *ngIf="this.patientData.coberturas.length == 0"> No tiene mutuales </mat-list-item>
      <div *ngFor="let c of this.patientData.coberturas">
        <mat-list-item  role="listitem"> {{c.mutual | toTitleCase}} - {{c.nroCarnet}} </mat-list-item>
      </div>
    </mat-list><br>
  </div>
<div *ngIf="hayMuchasCoberturas" class="scroll-indicator" (click)="scrollDown()">
  <mat-icon>keyboard_arrow_down</mat-icon>
  <span>Desplázate hacia abajo</span>
</div>
<div class="row" style="border: 0px solid blue; float: right;">
  <button mat-raised-button color="warn" [mat-dialog-close]="false">Cerrar</button>
</div>