<div class="container-fluid">

  <div class="row" style="margin-top: 1%;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9" style="margin: auto;">
      <mat-card *ngIf="cargoPaciente">
        <mat-card-header>
          <mat-card-title>Historial de Laboratorio</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <p><strong>Paciente:</strong> {{paciente.apellido}}, {{paciente.nombre}}</p>
              <p><strong>Sexo:</strong> {{paciente.sexo}}</p>
              <p><strong>Telefono:</strong> {{paciente.telefono}}</p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <p><strong>Fecha de Nacimiento:</strong> {{paciente.fechaNacimiento}}</p>
              <p><strong>Edad:</strong> {{paciente.edad}}</p>
              <p><strong>Domicilio:</strong> {{paciente.domicilio}}</p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <button mat-icon-button color="primary" (click)="volver()">
                <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row" style="margin-top: 1%;">
    <mat-progress-bar mode="indeterminate" *ngIf="cargando" style="width: 80%; margin: auto;"></mat-progress-bar>
  </div>

  <!-- <table mat-table [dataSource]="arregloItems | keyvalue" class="mat-elevation-z8">
  

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef> Determinacion </th>
      <td mat-cell *matCellDef="let element"> {{element.key}} </td>
    </ng-container>
  
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <tr>
          <td *ngFor="let item of element.value.items" style="border: 1px solid red;">{{item.resultado}}</td>
        </tr>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table> -->

  <div class="row" *ngIf="!cargando && hayElementos">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10" style="margin: auto;">
      <mat-form-field>
        <mat-label>Categoria</mat-label>
        <mat-select>
          <mat-option (click)="seleccionarCategoria('AMBAS')">Ambas</mat-option>
          <mat-option (click)="seleccionarCategoria('I')">Internado</mat-option>
          <mat-option (click)="seleccionarCategoria('A')">Ambulatorio</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field>
        <mat-label>Determinaciones</mat-label>
        <mat-select>
          <mat-option (click)="seleccionarDeterminacion('TODAS')">Todas</mat-option>
          <mat-option *ngFor="let item of arregloItems | keyvalue"  [hidden]="item.key == 'undefined'" (click)="seleccionarDeterminacion(item)">{{item.key}}</mat-option>
        </mat-select>
      </mat-form-field>

      <table class="table table-bordered table-striped table-sm table-responsive" ng-show="listaResultados.length > 0" style="width: fit-content;">
        <thead>
            <tr>
              <th></th>
              <th *ngFor="let resultado of listaResultados">{{ resultado.fechaHoraEfec }} ({{ resultado.categoria }})</th>
            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arregloItems | keyvalue" [hidden]="seleccionoDeterminacion || item.key == 'undefined'">
            <td>{{ item.key }}</td>
            <td *ngFor="let subValue of item.value.items">{{ subValue.resultado }}</td>
          </tr>
          <tr [hidden]="!seleccionoDeterminacion">
            <td>{{ nombreDeterminacionSeleccionada }}</td>
            <td *ngFor="let item of valoresDeterminacionSeleccionada">{{ item.resultado }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row" *ngIf="!cargando && !hayElementos" style="text-align: center !important;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10" style="margin: auto;">
      <h5 *ngIf="!error">No hay elementos para mostrar</h5>
      <h5 *ngIf="error" style="color: red;">No pudimos resolver tu consulta, por favor, intente más tarde.</h5>
    </div>
  </div>
</div>