import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { AgendaService } from '../_services/agenda.service';
import { PacienteService } from '../_services/paciente.service';
import { TurnoAdapter } from '../_models/turno.model';
import { EstudioService } from '../_services/estudios.service';
import * as moment from 'moment';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Turno } from '../_models/turno.model';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../_services/alert.service';
import { Router, NavigationStart } from '@angular/router';
import { AdministracionAgendasService } from '../_services/administracion-agendas.service';
import { DialogCancelarTurnosComponent } from '../dialog-cancelar-turnos/dialog-cancelar-turnos.component';
import { UserService } from '../_services/user.service';
import { RespuestaTurno } from '../_models/respuestaTurno.model';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { SucursalService } from '../_services/sucursal.service';
import { Moment } from 'moment';
import { toTitleCasePipe } from '../_pipes/toTitleCase.pipe';
import { DialogReciboApheComponent } from '../dialog-recibo-aphe/dialog-recibo-aphe.component';
import { UrlResolverService } from '../_services/urlResolver.service';
import { EstadoEnAtencion, EstadoEnEstudios, EstadoEsperando, EstadoLlamando } from '../_models/estadoTurno.model';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';
import { DialogAtencionAgendaComponent } from '../dialog-atencion-agenda/dialog-atencion-agenda.component';
import { DialogCambiarContrasenaComponent } from '../dialog-cambiar-contrasena/dialog-cambiar-contrasena.component';
import { DialogCambiarCorreoComponent } from '../dialog-cambiar-correo/dialog-cambiar-correo.component';
import { DialogSolicitarEstudioComponent } from '../dialog-solicitar-estudio/dialog-solicitar-estudio.component';
import { DialogSelectCoberturaComponent } from '../dialog-select-cobertura/dialog-select-cobertura.component';

export interface Profesional {
  matricula: number;
  nombreCompleto: string;
}

export interface RespuestaProfesionales {
  siguiente: boolean;
  ok: boolean;
  profesionales: Profesional[];
  mensaje: string;
}

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],
  providers: [
    toTitleCasePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es_AR' },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})

export class AgendaComponent implements OnInit {
  respuestaTurnos = new RespuestaTurno();
  loading: boolean;
  loadingProfesionales= true;
  fechaSeleccionada: Date;
  horaIni: any;
  horaFin: any;
  tvBloqueado = false;
  timeOut;
  nombreConsultorio: any;
  desdeVigencia: any;
  consultorio: any;
  listaSucursales: any[];
  sucursalSeleccionada: any;
  respuestaProfesionales: RespuestaProfesionales;
  profesionalSeleccionado: number;
  listaConsultorios: any[]=[];
  consultorioActual: any;
  profesionalRecibido: any;
  cargado = false;
  matricula;  
  profesionalNombreCompleto: String;
  agenda;
  efector_estudios: String;
  soySecretaria: boolean;
  diaDeHoy: Date = new Date();
  btnVolver=false;
  displayedColumns: string[] = ['horario', 'numero', 'paciente','triage','estado', 'acciones'];
  turno: any;
  tieneSucursales = false;
  mensajeRespuesta;
  servicioId:number;
  msjEstado: string;
  functionEstado: string;
  currentUser;
  errorRecuperarUsuario = false;
  idServicio: number;

  constructor(
    private authService: AuthenticationService,
    private agendaService: AgendaService,
    private pacienteService: PacienteService,
    private administradorService: AdministracionAgendasService,
    private sucursalService: SucursalService,
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private turnoAdapter: TurnoAdapter,
    private titleCasePipe: toTitleCasePipe,
    private estudiosService: EstudioService,
    private authenticationService: AuthenticationService,
  ) {
    history.pushState(null, null, location.href);

    router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        history.pushState(null, null, location.href);
      }
    });

    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    }, (error) => {
      console.log(error);
      this.errorRecuperarUsuario = true;
    });
  }

  async ngOnInit() {
    this.openDialogCambiarCorreo();
    this.iniciarUsuario(this.authService.currentUserValue);
    this.listaSucursales = [];
    let aux = this.userService.perfilSecretaria;
    if (this.perfilSecretaria()) {
      // console.log(aux)
      aux.forEach((perfil) => {
        this.listaSucursales.push({
          id: parseInt(perfil.codigoSucursal),
          nombre: perfil.nombreSucursal,
        });
      });
      this.sucursalSeleccionada = this.listaSucursales[0].id;
      this.sucursalService.setCurrentSucursal(this.sucursalSeleccionada);
      this.soySecretaria = true;
      this.tieneSucursales = true;
      
    } else {
      this.matricula = this.authService.currentUserValue.roles.find(
        (rol) => rol.codigo === 'MHE'
      ).valorTipoID;
      /*carlalu, solucion temporal hasta refactorizar el codigo que tiene muchos problemas*/
      //this.sucursalSeleccionada=1;

      //mauriciono, corregido con un await en cargarSucursales. 
      /*fin de la solución temporal */
      sessionStorage.setItem('matriculaAux',this.matricula); //me guardo la matricula para pasarla a paciente
      await this.cargarSucursales(this.matricula);
      this.cargarProfesional(this.matricula);
      
    }
    if (sessionStorage.getItem('filtrado')){
      this.btnVolver=true;
      this.mostrarAgendaBusqueda();
    }
    if (this.tieneSucursales){
      this.cargarProfesionales(this.sucursalSeleccionada);
      this.cargarConsultorios(this.sucursalSeleccionada);
      // this.ordenarConsultorios();
      this.respuestaTurnos.turnos = [];
      setInterval(this.updateTiempoEspera, 1000); 
      /*
      this.fechaSeleccionada = new Date();
      */
      if(!this.matricula){
       if ((this.respuestaProfesionales)&&(this.respuestaProfesionales.profesionales.length !== 0)) {
          this.matricula = this.respuestaProfesionales.profesionales[0].matricula;
          this.profesionalSeleccionado = this.matricula;
          this.profesionalNombreCompleto = this.respuestaProfesionales.profesionales[0].nombreCompleto;
          
          
        } else {
          this.matricula = null;
          this.profesionalSeleccionado = null;
        }
      } 
      // console.log(this.matricula)
     this.cargarAgenda(this.matricula,this.sucursalSeleccionada,this.fechaSeleccionada,this.horaIni);
     this.profesionalNombreCompleto= this.profesionalRecibido;
    } else {
      this.cargado = true;
    }
  }

  iniciarUsuario(usuario) {
    // console.log(usuario)
    if (new Date().getTime() > new Date(usuario.fechaExpiracionContrasena).getTime()) {
      this.openDialogCambiarContrasena(usuario);
    }
  }

  openDialogCambiarContrasena(objUsuarioLog) {
    this.dialog.open(DialogCambiarContrasenaComponent, {
      width: '500px',
      data: objUsuarioLog,
      hasBackdrop: true,
      disableClose: true
    }).afterClosed().subscribe(logout => {
      if (logout) {
        this.authService.logout();
        this.router.navigate(['/login']);
      }
    });
  }

  async openDialogCambiarCorreo() {
    let cerroPopupString = sessionStorage.getItem('cerroPopup');
    let cerroPopupBoolean = cerroPopupString === 'true' ? true : false;
    let mailActualizado;
    if (!cerroPopupBoolean) {
      await this.userService.getUsuarioPorUsername(this.currentUser.usuario).toPromise().then(
        (res) => {
          mailActualizado = res.paginador.elementos[0].mailActualizado;
        }, (error) => {
          console.log(error);
          this.errorRecuperarUsuario = true;
        }
      )
    }
    
    if (!cerroPopupBoolean && !mailActualizado && !this.errorRecuperarUsuario) {
      this.dialog.open(DialogCambiarCorreoComponent, {
        width: '500px',
        data: this.currentUser,
      });
    }
  }

  seleccionarDiaDeHoy() {
    this.loading = true;
    this.fechaSeleccionada = new Date();
    this.refrescarTurnos();
    // this.onDateChange(this.diaDeHoy);
  }

  insertarEstablecimiento(establecimiento: any) {
    this.listaSucursales.push({
      id: parseInt(establecimiento.codigo),
      nombre: establecimiento.nombre,
    });
  }

  mostrarAgendaBusqueda(){
    sessionStorage.removeItem('filtrado');
    if(sessionStorage.getItem('matriculaProfesional')) {
      this.matricula =sessionStorage.getItem('matriculaProfesional');
      this.profesionalSeleccionado = this.matricula;
    }

    if(sessionStorage.getItem('profesionalSeleccionado')) {
      this.matricula =sessionStorage.getItem('profesionalSeleccionado');
      this.profesionalSeleccionado = this.matricula;
    }

    let id = sessionStorage.getItem('establecimientoCodigo');
    this.fechaSeleccionada= new Date (sessionStorage.getItem('fecha'));
    this.horaIni=sessionStorage.getItem('horaInicio');
    let nombrecompletoProfesional = sessionStorage.getItem('nombreCompletoProf');
    this.profesionalRecibido=nombrecompletoProfesional; 
    
    sessionStorage.removeItem('matriculaProfesional');
    sessionStorage.removeItem('nombreCompletoProf');   
    sessionStorage.removeItem('establecimientoNombre');
    sessionStorage.removeItem('establecimientoCodigo'); 
    sessionStorage.removeItem('horaInicio');   
    sessionStorage.removeItem('fecha');
  }

  onVolver(){
    this.router.navigate(['/busquedaTurnos']);
  }

  refrescarTurnos() {
    // this.sucursalService.setCurrentSucursal(this.sucursalSeleccionada);
    // this.cargarConsultorios();
    this.cargarAgendaPorFecha(this.matricula, this.sucursalSeleccionada);
  }

  cargarProfesional(matricula){
    this.agendaService.getProfesional(matricula).subscribe(
      (data) => {
        this.profesionalNombreCompleto = data.paginador.elementos[0].apellido;
        
      },
      (error) => {
        console.log(error);
      }
    )
  }

  cargarAgendaPorFecha(matricula, sucursal) {
   const fechaSiguienteBloque = moment(this.fechaSeleccionada);
    this.agendaService
      .getProximoBloque(
        fechaSiguienteBloque.date(),
        fechaSiguienteBloque.month() + 1,
        fechaSiguienteBloque.year(),
        '00',
        '00',
        matricula,
        sucursal,
        null
      )
      .subscribe(
        (data) => {
          // console.log(data)
          this.idServicio = data.idServicio;
          this.callbackFunctionHorarios(data, matricula);
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  async openDialogReciboAphe(turnoSeleccionado) {
     let mutualesPaciente = this.pacienteService.mutualesDePaciente(turnoSeleccionado.numeroDoc, turnoSeleccionado.tipoDoc).subscribe((data) => {
      if (data.ok) {
        turnoSeleccionado.nroAfiliado = data.paginador.elementos.find(mutual => mutual.nombreMutual === turnoSeleccionado.nombreMutual);
        turnoSeleccionado.nroAfiliado = turnoSeleccionado.nroAfiliado.nroCarnet;
        
      }
      else {
        turnoSeleccionado.nroAfiliado = "ocurrió un error al recuperar el número de afiliado"
        
      }
        
     });
     const dialogRef = this.dialog.open(DialogReciboApheComponent, {
      width: 'auto',
      height: '550px',
      data: turnoSeleccionado,
    });
  }

  perfilSecretaria(): any {
    let aux = this.userService.perfilSecretaria;
    return aux != null && aux.length > 0; //retorna verdadero si es secretaria (tiene al menos un perfil dentro del rol Empleado Sucursal)
  }

  public soySecretariaEstado(): boolean {
    return this.soySecretaria;
  }
  
  getDay(date: Date) {
    if (date instanceof Date) {
      return date.toLocaleDateString('es-AR', { weekday: 'long' });
    }
  }

  itsToday() {
    return moment(this.fechaSeleccionada).format('L') === moment().format('L');
  }

  pastTime() {
    return moment(this.fechaSeleccionada).isBefore(moment().startOf('day'));
  }

  pastTimeSpecific(turno) {
    const specificDate = moment(
      moment(this.fechaSeleccionada).format('DD/MM/YYYY') +
        ' ' +
        turno.str_hora,
      'DD/MM/YYYY HH:mm'
    );
  
    return moment(specificDate).isBefore(moment());
  }

  async cargarProfesionales(sucursal) {
    await this.agendaService.getProfesionalesSucursal(sucursal).toPromise().then((data) => {
      // console.log(data)
      if (data) {
        data.profesionales = data.profesionales.sort(function (a, b) {
          if (a.nombreCompleto > b.nombreCompleto) {
            return 1;
          }
          if (a.nombreCompleto < b.nombreCompleto) {
            return -1;
          }
          return 0;
        });

        this.respuestaProfesionales = data;         
        this.cargado = true;
      }
    });
    this.loadingProfesionales = false;
    this.asignarProfesionalPrevio();
  }

  asignarProfesionalPrevio() {
    if(sessionStorage.getItem('profesionalSeleccionado')) {         
      let matricula = Number(sessionStorage.getItem('profesionalSeleccionado'));
      sessionStorage.removeItem('profesionalSeleccionado');

      if (this.perfilSecretaria()) {
        let profesional= this.respuestaProfesionales.profesionales.filter((prof) => {
          if (matricula === Number(prof.matricula)) {
            return prof;
          }
        });     
        this.profesionalSeleccionado= profesional[0].matricula;
      } else {
        this.profesionalSeleccionado=matricula;
      }
    }

    if (sessionStorage.getItem('fechaSeleccionada')) {
      this.fechaSeleccionada = new Date(sessionStorage.getItem('fechaSeleccionada'));
      sessionStorage.removeItem('fechaSeleccionada');
    }

    if (sessionStorage.getItem('horaIni')) {
      this.horaIni = sessionStorage.getItem('horaIni');
      sessionStorage.removeItem('horaIni');
    }

    this.cargarConsultorios(this.sucursalSeleccionada);
    this.cargarAgenda(Number(this.profesionalSeleccionado), this.sucursalSeleccionada, this.fechaSeleccionada, this.horaIni);
  }

  async cargarSucursales(matricula) {
    await this.administradorService.getSucursalesProfesional(matricula).toPromise().then(
      (data) => {
        // console.log(data);
        if (data) {
          this.listaSucursales = data.elementos;
          if(this.listaSucursales.length > 0){
            this.tieneSucursales = true;
            this.sucursalSeleccionada = this.listaSucursales[0].id;
            this.sucursalService.setCurrentSucursal(this.sucursalSeleccionada);
            this.cargarConsultorios(this.sucursalSeleccionada);
          } else {
            this.tieneSucursales = false;
            this.mensajeRespuesta= data.mensaje;
          }
          
          //this.cargarAgenda(matricula,this.sucursalSeleccionada,null,null);
          //this.cargado = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  cargarConsultorios(sucursal) {
    this.administradorService
      .getConsultoriosSucursal(sucursal)
      .subscribe((data) => {
        // console.log(data)
        if (data) {
          this.listaConsultorios = data.consultorios;
          /*Limpiar la lista de consultorios*/
          
          
          this.listaConsultorios = this.listaConsultorios.filter((consul) => {
            if (consul.descripcion !== '-') {
              return consul;
            }
            
          });
        
        }
        this.ordenarConsultorios();
      });
  }

  ordenarConsultorios() {
    this.listaConsultorios.sort( (a, b) => a.ubicacion < b.ubicacion ? -1 : 1)
  }

  cancelarTurnos() {
    const dialogRef = this.dialog.open(DialogCancelarTurnosComponent, {
      width: '400px',
      data: {
        agenda: this.agenda,
        frecuencia: this.respuestaTurnos.turnos[0].frecuencia,
        matricula: this.matricula,
        fecha: this.fechaSeleccionada,
        desdeVigencia: this.desdeVigencia,
        sucursalActual: this.sucursalSeleccionada,
      },
    }).afterClosed().subscribe((cancelo) => {
      if (cancelo) {
        // this.cargarAgenda(this.matricula);
        this.callbackFunctionHorarios(this.agenda, this.matricula);
      }
    });
  }

  levantarcancelacionesTurnos() {
    this.dialog
      .open(DialogConfirmacionComponent, {
        data: `¿Seguro desea levantar las cancelaciones de la agenda? `,
      })
      .afterClosed()
      .subscribe(
        (confirmado: boolean) => {
          if (confirmado) {
            this.agendaService
              .levantarCancelacionHorario(
                this.matricula,
                this.fechaSeleccionada,
                this.agenda.str_horaInicio,
                moment(this.agenda.desdeVigencia, 'DD/MM/YYYY').toDate(),
                this.respuestaTurnos.ordenDesdeCancelaciones,
                this.respuestaTurnos.ordenHastaCancelaciones
              )
              .subscribe(
                (respuestaCancelacion) => {
                  this.loading = false;
                  this.dialog
                    .open(DialogMensajeComponent, {
                      data: respuestaCancelacion,
                    })
                    .afterClosed()
                    .subscribe(() => {
                      if (respuestaCancelacion.ok) {
                        this.dialog.closeAll();
                        this.callbackFunctionHorarios(
                          this.agenda,
                          this.matricula
                        );
                        // window.location.reload();
                      }
                    });
                },
                (error) => {
                  console.log('error' + error);
                }
              );
          } else {
            this.dialog.closeAll();
            // const dialogRef = this.dialog.open(DialogMensajeComponent, {
            //   width: '500px',
            //   data: {
            //     ok: false,
            //     mensaje: 'Debe ingresar datos validos',
            //   },
            // });
          }
        },
        (error) => {
          console.log('error' + error);
        }
      );
  }

  seleccionoConsultorio(consultorio) {
    if (!consultorio.numero) {
      // this.consultorioActual = consultorio;
      this.consultorioActual = this.listaConsultorios.filter((consul) => {
        if (consultorio.id === consul.id) {
          return consul;
        }
      })[0];
    } else {
      this.consultorioActual = this.listaConsultorios.filter((consul) => {
        if (consultorio.numero === consul.id) {
          return consul;
        }
      })[0];
    }
  }

  seleccionoSucursal(seleccionada) {
    
    this.sucursalSeleccionada = seleccionada;
    this.sucursalService.setCurrentSucursal(seleccionada);
    this.cargarConsultorios(this.sucursalSeleccionada);
    this.cargarAgenda(this.matricula,this.sucursalSeleccionada,null,null);
  }

  seleccionoProfesional(seleccionado) {
    this.profesionalSeleccionado = seleccionado;
    this.matricula = seleccionado;
    this.cargarConsultorios(this.sucursalSeleccionada);
    this.cargarAgenda(this.profesionalSeleccionado,this.sucursalSeleccionada,null,null);
  }

  onDateChange(event) {
    this.agendaService.getProximoBloque(
      event.value.date(),
      event.value.month() + 1,
      event.value.year(),
      '00',
      '00',
      this.matricula,
      this.sucursalSeleccionada,
      null
    ).subscribe((data) => {
      // console.log(data),
      this.callbackFunctionHorarios(data, this.matricula);
    }, (error) => {
      console.log(error);
    });
  }

  habilitadoTurnoweb() {
    return this.agenda.efector_estudios === 'N';
  }

  
  cargarAgenda(matricula:number,sucursalSeleccionada:number, fecha: any, horaInicio:any) {
    sessionStorage.setItem('codigoSucursal',this.sucursalSeleccionada);
    let fechaSiguienteBloque:any; 
    let hora='00';
    let min='00';
    if(fecha !=null){
      fechaSiguienteBloque = moment(fecha);
      if(horaInicio !=null){ 
        hora=horaInicio.substring(0,2);
        min=horaInicio.substring(3, 4);
      }
    }else{
      fechaSiguienteBloque = moment();
    }
    if(!this.matricula && matricula){
      this.matricula=matricula;
    }

    if (this.matricula) {
      this.agendaService.getProximoBloque(
        fechaSiguienteBloque.date(),
        fechaSiguienteBloque.month() + 1,
        fechaSiguienteBloque.year(),
        hora,
        min,
        this.matricula,
        sucursalSeleccionada,
        null
      ).subscribe((data) => {
        //console.log(data)
        this.idServicio = data.idServicio
        this.callbackFunctionHorarios(data, this.matricula);
      });
    }
  }

  callbackFunctionHorarios(data, matricula) {
   this.agenda = data;
    if (data.desdeVigencia) {
      const fechaSiguienteBloque2 = moment(data.str_fechaInicio, 'DD/MM/YYYY');
      this.fechaSeleccionada = fechaSiguienteBloque2.toDate();
      this.horaIni = data.str_horaInicio;
      this.horaFin = data.str_horaFin;
      this.nombreConsultorio = data.servicio;
      this.desdeVigencia = data.desdeVigencia;
      this.consultorio = data.consultorio;
      const hora = data.str_horaInicio.substring(0, 2);
      const min = data.str_horaInicio.substring(3, 5);
      this.cargarTurnos(fechaSiguienteBloque2, hora, min, matricula);
      if (!this.administradorService.getCurrentConsultorio()) {
        this.seleccionoConsultorio(data.consultorio);
      } else {
        this.seleccionoConsultorio(
          this.administradorService.getCurrentConsultorio()
        );
        this.administradorService.removeCurrentConsultorio();
      } 
    } else {
      this.respuestaTurnos.turnos = [];
    }
  }

  imprimirRP(pedido) {
    const dialogRef = this.dialog.open(DialogPdfComponent, {
      width: '1300px',
      height: '700px',
      data: {
        url: this.estudiosService.imprimirRP({numero: pedido.numeroPedido}),
      },
    });
  }

  cargarTurnos(fechaSiguienteBloque: moment.Moment, hora, min, matricula) {
    this.loading = true;
    this.agendaService.getTurnosProfesional(
      fechaSiguienteBloque.date(),
      fechaSiguienteBloque.month() + 1,
      fechaSiguienteBloque.year(),
      hora,
      min,
      matricula
    ).subscribe((data) => {
      // console.log(data)
      this.respuestaTurnos = data; 
      this.respuestaTurnos.turnos = data.turnos.map((item) =>
        this.turnoAdapter.adapt(item)
      );

      //SI NO SOY LA SECRETARIA ME GUARDO EL TURNO EN ATENCION SI EXISTE
      if (!this.soySecretaria) {
        const atendiendo = this.respuestaTurnos.turnos.find(
          (turno) => !!turno.estado && turno.estado.getCodigo() == '2'
        );
        // console.log(atendiendo)
        if (atendiendo) {
          sessionStorage.setItem('currentPatientDNI', atendiendo.numeroDoc);
          sessionStorage.setItem('currentPatientName', this.titleCasePipe.transform(atendiendo.str_paciente));
          sessionStorage.setItem('currentPatientTipoDNI', atendiendo.tipoDoc);
          sessionStorage.setItem('turnoEnAtencion', JSON.stringify(atendiendo));
          // console.log(sessionStorage)
        }
      }
      // console.log(this.respuestaTurnos.turnos)
      
      this.turno = new MatTableDataSource(this.respuestaTurnos.turnos);
      // console.log(this.turno)
      this.loading = false;
      this.servicioId = data.servicioId;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  bloqueSiguiente() {
   const fechaSiguienteBloque = moment(this.fechaSeleccionada);
    const hora = this.horaIni.substring(0, 2);
    const min = (this.horaIni.substring(3, 5));
    let minutos= +(min);
    minutos +=1;
    this.agendaService
      .getProximoBloque(
        fechaSiguienteBloque.date(),
        fechaSiguienteBloque.month() + 1,
        fechaSiguienteBloque.year(),
        hora,
        minutos,
        this.matricula,
        this.sucursalSeleccionada,
        null
      )
      .subscribe(
        (data) => {
          //console.log(data)
          this.idServicio = data.idServicio;
          this.callbackFunctionHorarios(data, this.matricula);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  bloqueAnterior() {
    const fechaSiguienteBloque = moment(this.fechaSeleccionada);
    const hora = this.horaIni.substring(0, 2);
    const min = this.horaIni.substring(3, 5);
    this.agendaService
      .getAnteriorBloque(
        fechaSiguienteBloque.date(),
        fechaSiguienteBloque.month() + 1,
        fechaSiguienteBloque.year(),
        hora,
        min,
        this.matricula,
        this.sucursalSeleccionada,
        null
      )
      .subscribe(
        (data) => {
          // console.log(data)
          this.idServicio = data.idServicio;
          this.callbackFunctionHorarios(data, this.matricula);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  bloqueTurnoLibre() {
    const fechaSiguienteBloque = moment(this.fechaSeleccionada);
    const desdeVigencia = moment(this.desdeVigencia, 'DD/MM/YYYY');
    const hora = this.horaIni.substring(0, 2);
    const min = this.horaIni.substring(3, 5);
    this.agendaService
      .getBloqueTurnoLibre(
        fechaSiguienteBloque.date(),
        fechaSiguienteBloque.month() + 1,
        fechaSiguienteBloque.year(),
        hora,
        min,
        this.matricula,
        this.sucursalSeleccionada,
        null,
        desdeVigencia.date(),
        desdeVigencia.month() + 1,
        desdeVigencia.year()
      )
      .subscribe(
        (data) => {
          // console.log(data)
          this.idServicio = data.idServicio
          this.callbackFunctionHorarios(data, this.matricula);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  public registrarLLegadaPaciente(turno: Turno) {
    const fechaSiguienteBloque = moment(this.fechaSeleccionada);
    const hora = this.horaIni.substring(0, 2);
    const min = this.horaIni.substring(3, 5);
    this.agendaService
      .registrarLLegadaPaciente(
        fechaSiguienteBloque.date(),
        fechaSiguienteBloque.month() + 1,
        fechaSiguienteBloque.year(),
        hora,
        min,
        turno.numero,
        this.matricula,
        this.authService.currentUserValue.usuario
      )
      .subscribe(
        (data) => {
          this.cargarTurnos(fechaSiguienteBloque, hora, min, this.matricula);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  analizarPaciente(turno: Turno) {
    this.registrarLLegadaPaciente(turno);
    if (turno.tipoDoc== 9 || turno.tipoDoc=='9'){      
      sessionStorage.setItem('estaConfirmandoPaciente', JSON.stringify(true));
      this.abrirDialogConfirmarPaciente(turno);
    }
  }

  registrarTemporal(turno:Turno){
     if (turno.tipoDoc== 9 || turno.tipoDoc=='9'){      
      this.abrirDialogConfirmarPaciente(turno);
    }
  }

  abrirDialogConfirmarPaciente(turno:any) {
    const dialogRef = this.dialog.open(DialogConfirmacionComponent, {
      data: `¿Desea confirmar al paciente? `,
    })
    .afterClosed()
      .subscribe(
        (confirmado: boolean) => {
          if (confirmado) {
            //this.router.navigate(['/ver-formularios-episodio']);
            const dialogRef =  this.dialog.open(SearchPacientesDialog,{
              width: '500px',
             data: {registrarPaciente: true,turno,sucursalSeleccionada:this.sucursalSeleccionada},
            });
            dialogRef.afterClosed().subscribe(async (result) => {
              if (result) {
                //pegarle al back
                 await this.pacienteService.fusionarPacientes(turno.tipoDoc,turno.numeroDoc,result.tipoDocumento,result.nroDocumento)
                .toPromise().then(
                  (x) => {
                   
                  },
                  (error) => {
                    console.log(error);
                  }
                );
                
              }
            });
          } else {
            this.dialog.closeAll();
          }
        },
      );
  }

  abrirDialogConfirmarCancelacion(turno:any) {
    const dialogRef = this.dialog.open(DialogConfirmacionComponent, {
      data: `¿Está seguro de cancelar el turno? `,
    }).afterClosed().subscribe((confirmado: boolean) => {
      if (confirmado) {
        this.cancelacionConfirmada(turno);
      }
      // this.dialog.closeAll();
    });
  }

  async cancelacionConfirmada(turno: any) {
    const fechaSiguienteBloque = moment(this.fechaSeleccionada);
    await this.agendaService.cancelarTurno(
      this.authService.currentUserValue.usuario,
      this.matricula,
      this.horaIni,
      turno.numero,
      fechaSiguienteBloque.date(),
      fechaSiguienteBloque.month() + 1,
      fechaSiguienteBloque.year(),
      turno.str_hora,
      turno.numeroDoc,
      turno.tipoDoc,
      this.sucursalSeleccionada
    ).toPromise().then((rpCancelar) => {
      // console.log(rpCancelar);
      if (rpCancelar.ok) {
        const hora = this.horaIni.substring(0, 2);
        const min = this.horaIni.substring(3, 5);
        this.cargarTurnos(fechaSiguienteBloque, hora, min, this.matricula);
      } else {
        rpCancelar.mensaje = "El turno no puede ser cancelado debido a que el paciente no tiene correo para notificarlo. Comuníquese con el área correspondiente para que haga la cancelación e informe al paciente."
        this.dialog.open(DialogMensajeComponent, {
          width: '600px',
          data: rpCancelar,
        })
      }
    }, (error) => {
      console.log(error);
    });
  }

  cancelarTurno(turno:any) {
    this.abrirDialogConfirmarCancelacion(turno);
    /* METER EL DIALOG PARA LA CONFIRMACIÓN */
  }

  llamarTv(turno: Turno) {

    turno.estado = new EstadoLlamando();
    this.tvBloqueado = true;
    turno.llamadoTv = true;
    const fecha = moment(this.fechaSeleccionada);
    this.timerTv(turno);
    this.agendaService.llamarTv(
      this.consultorioActual.id,
      this.matricula,
      'LLA',
      '8',
      this.horaIni,
      fecha.format('DD/MM/YYYY'),
      turno.numero,
      turno.str_paciente,
      this.profesionalNombreCompleto,
      this.authService.currentUserValue.usuario,
      turno.numeroDoc,
      turno.tipoDoc,
      turno.numeroPedido
    ).subscribe((data) => {
      // console.log(data)
    }, (error) => {
      console.log(error);
    });
  }

  cancelarLLamarTv(turno: Turno) {
    const fecha = moment(this.fechaSeleccionada);

    if (this.timeOut) {
      this.stopTimerTv();
    }
    turno.estado = new EstadoEsperando();
    this.tvBloqueado = false;
    turno.llamadoTv = false;
    this.agendaService
      .llamarTv(
        this.consultorioActual.id,
        this.matricula,
        turno.estado.getCodigoTv(),
        turno.estado.getCodigo(),
        this.horaIni,
        fecha.format('DD/MM/YYYY'),
        turno.numero,
        turno.str_paciente,
        this.profesionalNombreCompleto,
        this.authService.currentUserValue.usuario,
        turno.numeroDoc,
        turno.tipoDoc,
        turno.numeroPedido
      )
      .subscribe(
        (data) => {},
        (error) => {
          console.log(error);
        }
      );
  }

  timerTv(turno) {
    this.timeOut = setTimeout(
      () => {
        this.tvBloqueado = false;
        turno.llamadoTv = false;        
        const fecha = moment(this.fechaSeleccionada);
        turno.estado = new EstadoEsperando();
        this.agendaService
          .llamarTv(
            this.consultorioActual.id,
            this.matricula,
            turno.estado.getCodigoTv(),
            turno.estado.getCodigo(),
            this.horaIni,
            fecha.format('DD/MM/YYYY'),
            turno.numero,
            turno.str_paciente,
            this.profesionalNombreCompleto,
            this.authService.currentUserValue.usuario,
            turno.numeroDoc,
            turno.tipoDoc,
            turno.numeroPedido
          )
          .subscribe(
            (data) => {},
            (error) => {
              console.log(error);
            }
          );
      },
      10000,
      turno
    );
  }

  stopTimerTv() {
    clearTimeout(this.timeOut);
  }

  selectionChange(event, stepper: MatStepper) {}

  goBack(stepper: MatStepper, turno: Turno) {
    if (stepper.selectedIndex === 0) {
      return;
    }

    const codigoTv = turno.estado.getAnteriorEstado().getCodigoTv();
    const codigoEstado = turno.estado.getAnteriorEstado().getCodigo();
    //SI NO SOY LA SECRETARIA ME GUARDO EL TURNO EN ATENCION
    if (!this.soySecretaria) {
      if (codigoEstado === '2') {
        sessionStorage.setItem('currentPatientDNI', turno.numeroDoc);
        sessionStorage.setItem('currentPatientName', this.titleCasePipe.transform(turno.str_paciente));
        sessionStorage.setItem('currentPatientTipoDNI', turno.tipoDoc);
        this.setEstadoTriagePaciente(turno, 'ENA');
        this.seleccionarPaciente(turno);
      } else {
        if (codigoEstado === '3') {
          sessionStorage.removeItem('currentPatientDNI');
          sessionStorage.removeItem('currentPatientName');
          sessionStorage.removeItem('currentPatientTipoDNI');
        } else {
          if (codigoEstado === '8') {
            sessionStorage.removeItem('currentPatientDNI');
            sessionStorage.removeItem('currentPatientName');
            sessionStorage.removeItem('currentPatientTipoDNI');
            this.setEstadoTriagePaciente(turno, 'ESP');
          }
        }
      }
    }

    const fecha = moment(this.fechaSeleccionada);
    this.agendaService
      .llamarTv(
        this.consultorioActual.id,
        this.matricula,
        codigoTv,
        codigoEstado,
        this.horaIni,
        fecha.format('DD/MM/YYYY'),
        turno.numero,
        turno.str_paciente,
        this.profesionalNombreCompleto,
        this.authService.currentUserValue.usuario,
        turno.numeroDoc,
        turno.tipoDoc,
        turno.numeroPedido
      )
      .subscribe(
        (data) => {
          const hora = this.horaIni.substring(0, 2);
          const min = this.horaIni.substring(3, 5);
          this.cargarTurnos(fecha, hora, min, this.matricula);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  setTurnoEnEstudios(turno: Turno) {
    // console.log(turno)
    turno.estado = new EstadoEnEstudios();
    const fecha = moment(this.fechaSeleccionada);
    this.agendaService.llamarTv(
      this.consultorioActual.id,
      this.matricula,
      turno.estado.getCodigoTv(),
      turno.estado.getCodigo(),
      this.horaIni,
      fecha.format('DD/MM/YYYY'),
      turno.numero,
      turno.str_paciente,
      this.profesionalNombreCompleto,
      this.authService.currentUserValue.usuario,
      turno.numeroDoc,
      turno.tipoDoc,
      turno.numeroPedido
    ).subscribe((data) => {
      // console.log(data);
      const hora = this.horaIni.substring(0, 2);
      const min = this.horaIni.substring(3, 5);
      this.cargarTurnos(fecha, hora, min, this.matricula);
    }, (error) => {
      console.log(error);
    });
  }

  analizarAtencion(stepper: MatStepper, turno: Turno) {
    // console.log(turno)
    if (sessionStorage.getItem('currentPatientName')) {
      if (turno.estado.getSiguienteEstado().getCodigo() === '2') {
        this.openDialogAtencion(stepper, turno, true);
      } else {
        this.goForward(stepper, turno);
        this.setEstadoTriagePaciente(turno, turno.estado.getEstadoSiguienteTriage());
      }
    } else {
      this.goForward(stepper, turno);
      this.setEstadoTriagePaciente(turno, turno.estado.getEstadoSiguienteTriage());
    }
  }

  setEstadoTriagePaciente(turno: Turno, estado: String) {
    let jsonTriage = {};
    if (turno.idTriage) {
      if (estado == 'ATE') {
				jsonTriage = {
          idTriage: turno.idTriage,
          fechaEgreso: moment().format("YYYY-DD-MM HH:mm:ss"),
          horaEgreso: moment().format("YYYY-DD-MM HH:mm:ss"),
          estado: {
            estado: estado
          }
				}
			} else {
				jsonTriage = {
          idTriage: turno.idTriage,
          estado: {
            estado: estado
          }
				}
			}
      
      this.pacienteService.setEstadoTriagePaciente(jsonTriage).toPromise().then(resp => {
        // console.log(resp)
      }, error => {
        console.log(error)
      })
    }
  }

  openDialogAtencion(stepper: MatStepper, turno: Turno, existeENA: Boolean) {
    this.dialog.open(DialogAtencionAgendaComponent, {

    }).afterClosed().subscribe((accion: String) => {
      // console.log(accion);
      switch (accion) {
        case 'FIN':
          this.loading = true;
          
          if (existeENA) {
            this.setEstadoTriagePaciente(JSON.parse(sessionStorage.getItem('turnoEnAtencion')), 'ATE');
            const turnoEnAtencion = JSON.parse(sessionStorage.getItem('turnoEnAtencion'));
              turnoEnAtencion.estado = new EstadoEnAtencion()
            this.goForward(stepper, turnoEnAtencion);
            this.goForward(stepper, turno);
          } else {
            this.setEstadoTriagePaciente(turno, 'ATE');
            this.goForward(stepper, turno);
          }

          sessionStorage.removeItem('currentPatientDNI');
          sessionStorage.removeItem('currentPatientName');
          sessionStorage.removeItem('currentPatientTipoDNI');
          sessionStorage.removeItem('turnoEnAtencion');
          break;

        case 'INT':
          this.loading = true;

          if (existeENA) {
            this.setEstadoTriagePaciente(JSON.parse(sessionStorage.getItem('turnoEnAtencion')), 'INT');
            const turnoEnAtencion = JSON.parse(sessionStorage.getItem('turnoEnAtencion'));
              turnoEnAtencion.estado = new EstadoEnAtencion()
            this.goForward(stepper, turnoEnAtencion);
            this.goForward(stepper, turno);
          } else {
            this.setEstadoTriagePaciente(turno, 'INT');
            this.goForward(stepper, turno);
          }
          break;

        case 'ENE':
          this.loading = true;

          if (existeENA) {
            this.setTurnoEnEstudios(JSON.parse(sessionStorage.getItem('turnoEnAtencion')));
            this.setEstadoTriagePaciente(JSON.parse(sessionStorage.getItem('turnoEnAtencion')), 'ENE');
            this.goForward(stepper, turno);
            this.setEstadoTriagePaciente(turno, turno.estado.getEstadoSiguienteTriage());
          } else {
            this.setTurnoEnEstudios(turno);
            this.setEstadoTriagePaciente(turno, 'ENE');
          }

          sessionStorage.removeItem('currentPatientDNI');
          sessionStorage.removeItem('currentPatientName');
          sessionStorage.removeItem('currentPatientTipoDNI');
          sessionStorage.removeItem('turnoEnAtencion');
          break;

        default:
          break;
      }
    });
  }

  goForward(stepper: MatStepper, turno: Turno) {
    // console.log(stepper)
    // console.log(turno)
    // console.log(sessionStorage)

    if (stepper.selectedIndex === 2) {
      return;
    }

    const codigoTv = turno.estado.getSiguienteEstado().getCodigoTv();
    const codigoEstado = turno.estado.getSiguienteEstado().getCodigo();
    //SI NO SOY LA SECRETARIA ME GUARDO EL TURNO EN ATENCION
    if (!this.soySecretaria) {
      if (codigoEstado === '2') {
        sessionStorage.setItem('currentPatientDNI', turno.numeroDoc);
        sessionStorage.setItem('currentPatientName', this.titleCasePipe.transform(turno.str_paciente));
        sessionStorage.setItem('currentPatientTipoDNI', turno.tipoDoc);
        sessionStorage.setItem('turnoEnAtencion', JSON.stringify(turno));
      } else if (codigoEstado === '3') {
        sessionStorage.removeItem('currentPatientDNI');
        sessionStorage.removeItem('currentPatientName');
        sessionStorage.removeItem('currentPatientTipoDNI');
        sessionStorage.removeItem('turnoEnAtencion');
      }
    }

    const fecha = moment(this.fechaSeleccionada);
    if (turno.estado.getCodigo() === '9') {
      this.stopTimerTv();
    }

    this.agendaService.llamarTv(
      this.consultorioActual.id,
      this.matricula,
      codigoTv,
      codigoEstado,
      this.horaIni,
      fecha.format('DD/MM/YYYY'),
      turno.numero,
      turno.str_paciente,
      this.profesionalNombreCompleto,
      this.authService.currentUserValue.usuario,
      turno.numeroDoc,
      turno.tipoDoc,
      turno.numeroPedido
    ).subscribe((data) => {
      const hora = this.horaIni.substring(0, 2);
      const min = this.horaIni.substring(3, 5);
      this.cargarTurnos(fecha, hora, min, this.matricula);
    }, (error) => {
      console.log(error);
    });
  }

  openDialog(turno: Turno) {
    const fecha = moment(
      moment(this.fechaSeleccionada).format('DD/MM/YYYY') +
        ' ' +
        turno.str_hora,
      'DD/MM/YYYY HH:mm'
    );

    if ((moment().isBefore(fecha) && turno.numero !== 1) || (moment().isBefore(fecha) && turno.numero == 1 && this.sucursalSeleccionada !== 1)) {
      const fecha2 = moment(this.fechaSeleccionada);
      this.agendaService
        .tomarTurno(
          this.authService.currentUserValue.usuario,
          this.matricula,
          turno.numero,
          this.horaIni,
          14,
          fecha2.format('DD/MM/YYYY'),
          0,
          9,
          this.desdeVigencia
        )
        .subscribe(
          (data) => {
            if (sessionStorage.getItem('currentPatientDNI') && sessionStorage.getItem('currentPatientTipoDNI')) {  // SI EXISTE CURRENT PATIENT
              this.dialog
                .open(DialogConfirmacionComponent, {
                  data: `¿Desea asignar el turno al paciente que esta siendo atendido ? (${sessionStorage.getItem(
                    'currentPatientName'
                  )})`,
                })
                .afterClosed()
                .subscribe((confirmado: boolean) => {
                  if (confirmado) {
                    this.confirmarTurno(
                      turno,
                      fecha,
                      sessionStorage.getItem('currentPatientDNI'),
                      sessionStorage.getItem('currentPatientTipoDNI'),
                      14,
                      null
                    );
                  } else {
                    const dialogRef = this.dialog.open(SearchPacientesDialog, {
                      width: '500px',
                      data: {
                        turno,
                        horaIni: this.horaIni,
                        fecha,
                        sucursalSeleccionada: this.sucursalSeleccionada
                      },
                    });
                    dialogRef.afterClosed().subscribe((result) => {
                      if (result && result.ok) {
                        let p = JSON.parse(sessionStorage.getItem('p'));
                        sessionStorage.removeItem('p');
                        this.confirmarTurno(
                          turno,
                          fecha,
                          p.nroDocumento,
                          p.tipoDocumento,
                          14,
                          null
                        );
                      } else {
                        this.liberarTurno(turno, fecha);
                      }
                    });
                  }
                });
            } else { // SINO, BUSCO PACIENTE
              const dialogRef = this.dialog.open(SearchPacientesDialog, {
                width: '500px',
                data: {
                  turno,
                  horaIni: this.horaIni,
                  fecha,
                  sucursalSeleccionada: this.sucursalSeleccionada,
                  idServicio: this.idServicio,
                  matriculaEfector: this.matricula,
                },
              }).afterClosed().subscribe((resultado) => {
                let p = JSON.parse(sessionStorage.getItem('p'));

                if (resultado && resultado.ok) {
                  let p = JSON.parse(sessionStorage.getItem('p'));
                  sessionStorage.removeItem('p');
                  //console.log(p)
                  this.confirmarTurno(
                    turno,
                    fecha,
                    p.nroDocumento,
                    p.tipoDocumento,
                    p.mutual,
                    p.numeroPedido
                  );

                } else {
                  this.liberarTurno(turno, fecha);
                }
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  confirmarTurno(
    turno: Turno,
    fecha: Moment,
    currentPatientDNI: string,
    currentPatientTipoDNI: string,
    mutual,
    numeroPedido
  ) {
    this.agendaService.confirmarTurno(
      this.authService.currentUserValue.usuario,
      this.matricula,
      turno.numero,
      this.horaIni,
      mutual,
      fecha.date(),
      fecha.month() + 1,
      fecha.year(),
      currentPatientDNI,
      currentPatientTipoDNI,
      turno.str_hora,
      this.sucursalSeleccionada,
      numeroPedido
    ).subscribe((data2) => {
      if (!data2.ok) {
        const dialogAssigned = this.dialog.open(PacienteYaAsignadoDialog, {
          width: '500px',
        });

        this.liberarTurno(turno, fecha);
      } else {
        const hora = this.horaIni.substring(0, 2);
        const min = this.horaIni.substring(3, 5);
        this.cargarTurnos(fecha, hora, min, this.matricula);
      }
    }, (error) => {
      console.log(error);
    });
  }

  liberarTurno(turno: Turno, fecha: Moment) {
    this.agendaService
      .liberarTurno(
        this.authService.currentUserValue.usuario,
        this.matricula,
        turno.numero,
        this.horaIni,
        fecha.date(),
        fecha.month() + 1,
        fecha.year()
      )
      .subscribe(
        (data2) => {
          const hora = this.horaIni.substring(0, 2);
          const min = this.horaIni.substring(3, 5);
          // this.cargarTurnos(fecha, hora, min, this.matricula);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  openDialogPacientes(): void {
    const dialogRef = this.dialog.open(SearchPacientesDialog, {
      width: '500px',
      data: {
        verPaciente: true
        ,sucursalSeleccionada:this.sucursalSeleccionada,
      },
    });



    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const paciente = {
          numeroDoc: result.nroDocumento,
          tipoDoc: result.tipoDocumento,
          str_paciente: result.apellido,
        };
        this.seleccionarPaciente(paciente);
      }
    });
  }

  tomarTurnoExtra() {
    let tamano = this.respuestaTurnos.turnos.length;
    let hora = this.respuestaTurnos.turnos[tamano-1].str_hora;
    hora = hora.split(":");
    let horarioTurno = new Date(0,0,0,+hora[0],+hora[1]+this.respuestaTurnos.turnos[0].frecuencia)
    hora = horarioTurno.toTimeString().slice(0,5);
    
    const turno = new Turno();
    turno.str_hora = hora;
    turno.numero = tamano+1;
    
    this.openDialog(turno);  
  }

  seleccionarPaciente(paciente) {
    this.pacienteService.setCurrentPatient(paciente);
    this.administradorService.setCurrentConsultorio(this.consultorioActual);
    if(this.perfilSecretaria())
     sessionStorage.setItem('profesionalSeleccionado', String(this.profesionalSeleccionado));
    else{
      sessionStorage.setItem('profesionalSeleccionado', String(this.matricula));
    }
    sessionStorage.setItem('fechaSeleccionada', String(this.fechaSeleccionada));
    sessionStorage.setItem('horaIni', String(this.horaIni));
    //sessionStorage.setItem('filtrado',String(true));
    this.router.navigate(['/paciente']);
    
  }

  registrarEstadistica(){
    this.userService.userData.subscribe(() => {
        this.msjEstado = 'El usuario ' + this.authService.currentUserValue.usuario + ' ha accedido a la agenda vieja';
        this.functionEstado = 'FX_VerAgendaVieja';
        this.userService.guardarEstadistica(this.authService.currentUserValue.usuario, this.msjEstado, this.functionEstado).subscribe((respGuardarEst) => {},
        (error) => {
          console.log(error);
        }
      );
    })
  }
  

  updateTiempoEspera() {
    // document.getElementById('tiempoEspera').textContent = ' ';
  }
}

@Component({
  selector: 'dialog-search-pacientes',
  templateUrl: 'dialog-search-pacientes.html',
  styleUrls: ['./agenda.component.css'],
})

export class SearchPacientesDialog {
  registrarPaciente =false;
  nroDoc;
  nombre;
  searched = false;
  pacientes = [];
  loading = false;
  patientData: any;
  serviciosHabilitadosPedidos: string;
  esSecretaria = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SearchPacientesDialog>,
    private pacienteService: PacienteService,
    private urlResolverService: UrlResolverService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}

  ngOnInit() {
    this.funcionDelInit();
    //this.getserviciosHabilitadosPedidos();
  }

  servicioCargaPedidos() {
    // return this.serviciosHabilitadosPedidos.match("@"+this.data.idServicio+"@") ? true : false;
    return this.serviciosHabilitadosPedidos.match("@18@") ? true : false;
  }

  getserviciosHabilitadosPedidos() {
    this.urlResolverService.getParamAppVariableConfig("SERVFACPED").toPromise()
      .then(rp => {
        // console.log(rp)
        this.serviciosHabilitadosPedidos = rp;
      }).catch(error => {
        console.log(error)
      }).finally(() => { })
  }
 
  funcionDelInit() {
    this.esSecretaria = this.perfilSecretaria();
    //console.log(this.esSecretaria);
    let buscar= sessionStorage.getItem('codigoTemporal');
    if (buscar){
      this.nroDoc=buscar;
      sessionStorage.removeItem('codigoTemporal');
      this.pacienteService.buscarPaciente(-1, -1, this.nroDoc).subscribe(
        (data) => {
          this.searched = true;
          this.pacientes = data.paginador.elementos;
        },
        (error) => {
          this.searched = true;
         this.loading = false;
        }
      );
    }
  }

  perfilSecretaria(): any {
    return this.userService.perfilSecretaria;
  }

  buscarPaciente() {
    this.loading = true;
    let doc = -1;
    let nombre = -1;
    if (this.nroDoc) {
      doc = this.nroDoc;
    }
    if (this.nombre) {
      nombre = this.nombre;
    }
    this.pacienteService.buscarPaciente(nombre, -1, doc).subscribe(
      (data) => {
        this.searched = true;
        this.pacientes = data.paginador.elementos;
        this.loading = false;
        if(this.registrarPaciente){
          //filtrar pacientes con tipoDocumento =='9'
        }
      },
      (error) => {
        this.searched = true;
        console.log(error);
        this.loading = false;
      }
    );
  }

  openCreatePatientDialog(datosOpen: any) {
    const dialogRef = this.dialog.open(CrearPacienteDialog, {
      width: '500px',
      data: datosOpen,
    });

    if (this.nroDoc && sessionStorage.getItem('estaConfirmandoPaciente')) {
      sessionStorage.setItem('documentoBuscado',this.nroDoc);
      sessionStorage.removeItem('estaConfirmandoPaciente');
    }

    dialogRef.afterClosed().subscribe(async (result) => {
        if (sessionStorage.getItem('tipoDoc')){
          let tipoDocDefinitivo= sessionStorage.getItem('tipoDoc');
          let nroDocDefinitivo= sessionStorage.getItem('nroDoc');
          sessionStorage.removeItem('tipoDoc');
          sessionStorage.removeItem('nroDoc');
        
          await this.pacienteService.fusionarPacientes(datosOpen.turno.tipoDoc,datosOpen.turno.numeroDoc,tipoDocDefinitivo,nroDocDefinitivo).toPromise().then(
            data => {
              if(data){
                //avisarle al usuario que la operación resultó exitosa.
                this.dialog.closeAll();
              }
            }
          );
      } else {
        this.funcionDelInit();
      }
    });
  }

  openDialog(p) {
      const dialogRef = this.dialog.open(DialogConfirmacionComponent, {
        data: `¿Está seguro que desea confirmar el turno? `,
      }).afterClosed().subscribe((confirmado: boolean) => {
        if (confirmado) {
          p.mutual = 14;
          p.numeroPedido = 'null';
          sessionStorage.setItem('p', JSON.stringify(p));
          this.dialogRef.close({ ok: true });
        }
      });

    //this.openSelectCobertura(p);
  }

  openSelectCobertura(p: any) {
    p.idServicio = this.data.idServicio;
    //console.log(p.idServicio);
    p.matriculaEfector = this.data.matriculaEfector;
    //console.log(this.data);
    const dialogRef = this.dialog.open(DialogSelectCoberturaComponent, {
      data: p,
      width: '450px',
    }).afterClosed().subscribe((resultado) => {
      if (resultado && resultado.ok) {
        //console.log(resultado);
        this.dialogRef.close(resultado);
      }
    })
  }
  
  openConfirmarTurno(p: any) {
    const dialogRef = this.dialog.open(DialogConfirmacionComponent, {
      data: `¿Está seguro que desea confirmar el turno? `,
    }).afterClosed().subscribe((confirmado: boolean) => {
      if (confirmado) {
        p.mutual = 14;
        p.numeroPedido = 'null';
        sessionStorage.setItem('p', JSON.stringify(p));
        this.dialogRef.close({ ok: true });
      }
    });
  }
}

@Component({
  selector: 'dialog-patient-already-assigned',
  templateUrl: 'dialog-patient-already-assigned.html',
  styleUrls: ['./agenda.component.css'],
})

export class PacienteYaAsignadoDialog {
  constructor(
    public dialogRef: MatDialogRef<PacienteYaAsignadoDialog>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
}

@Component({
  selector: 'dialog-create-patient',
  templateUrl: 'dialog-create-patient.html',
  styleUrls: ['./agenda.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es_AR' },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CrearPacienteDialog {
  registerForm: UntypedFormGroup;
  maxDate: Date = new Date();
  submitted: boolean = false;
  loading: boolean = true;
  genders = [
    { value: 'M', descripcion: 'Masculino' },
    { value: 'F', descripcion: 'Femenino' },
  ];
  //para pre cargar la info del paciente
  datosPaciente: any = {};
  private urlHorusRestfull: any;
  existeRecAphe: boolean = false;
  cargando: boolean = true;
  private tiposDocumentos:any[];
  private nroDoc=0;
  private sucursales:any[];
  private establecimientoActual:any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private urlService: UrlResolverService,
    private pacienteService: PacienteService,
    private sucursalService: SucursalService,
    private authService: AuthenticationService,
    private agendaService: AgendaService,
    public dialogRef: MatDialogRef<CrearPacienteDialog>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}

  async ngOnInit() {
   
    this.getHorusURL();

   if (this.data.turno){
      await this.getDatosPaciente(this.data.turno.tipoDoc, this.data.turno.numeroDoc);
  }

   if(sessionStorage.getItem('documentoBuscado')){
    this.nroDoc= Number(sessionStorage.getItem('documentoBuscado'));
    sessionStorage.removeItem('documentoBuscado');
  }

  await this.sucursalService.getEstablecimientos().toPromise().then(
    data =>{
      if(data.ok){
       this.sucursales= data.data;

        this.establecimientoActual=
        this.sucursales.filter(
          (sucursal) => sucursal.codigo == sessionStorage.getItem('codigoSucursal')
        );
      }
    }
  );


   this.pacienteService.getTiposDocumentos().subscribe(
      data=>{
        if(data.ok){
           this.tiposDocumentos=data.paginador.elementos;
        }
      }
    );
    this.construirFormulario();
  }

  construirFormulario(){
    const emailPattern: RegExp = /^[a-zA-Z0-9-_.]([\.]?[a-zA-Z0-9-_.])+@[a-zA-Z0-9]([^@&%$\/\(\)=?¿!\.,:;\s]|\d)+[a-zA-Z0-9]([\.][a-zA-Z]{2,})+$/

    this.registerForm = this.formBuilder.group({
      firstName: [ this.datosPaciente.nombre?this.datosPaciente.nombre : '',
        [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$')],
      ],
      lastName: [
        this.datosPaciente.apellido?this.datosPaciente.apellido : '',
        [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$')],
      ],
      mail: [
        this.datosPaciente.email?this.datosPaciente.email : '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(emailPattern),
        ],
      ],
      tel: [
        this.datosPaciente.telefono?this.datosPaciente.telefono : '',
        [
          Validators.required,
          Validators.pattern('[0-9]*'), 
          Validators.min(1000000), // Establece el valor mínimo
          Validators.max(9999999999999999), // Establece el valor máximo
        ],
      ],
      date: [ this.datosPaciente.fechaNacimiento? moment(this.datosPaciente.fechaNacimiento,'DD/MM/YYYY') : '', [Validators.required]],
      gender: [ this.datosPaciente.sexo=='MASCULINO'?'M' : 'F', Validators.required],
      location: [this.datosPaciente.domicilio?this.datosPaciente.domicilio : '', 
        [Validators.required, 
          Validators.pattern('^[0-9A-Za-zñÑáéíóúÁÉÍÓÚ ]+$'), 
          Validators.maxLength(60),
        ]
      ],
      city: [this.datosPaciente.localidad?this.datosPaciente.localidad : this.establecimientoActual[0].localidad, [Validators.required, Validators.pattern('^[0-9A-Za-zñÑáéíóúÁÉÍÓÚ ]+$'), ]],
      cod: [this.datosPaciente.cp?this.datosPaciente.cp : this.establecimientoActual[0].codigoPostal, [Validators.required, Validators.pattern('[0-9]*')]],
      tipoDocumento: [this.datosPaciente.tipoDocumento!= 9 ? this.datosPaciente.tipoDocumento : '0',],   
      nroDocumento: [this.nroDoc !=0? this.nroDoc : '', [Validators.pattern('[0-9]*')]],
    });
    this.loading=false;
  }

  async getHorusURL() {
    await this.urlService.getHorusfullUrl().toPromise().then(resp => {
      this.urlHorusRestfull = resp.paginador.elementos[0].valor;
    }, error => {
      console.log(error)
    })
  }

  async getDatosPaciente(tipoDni, nroDni) {
    await this.pacienteService.datosPaciente(nroDni, tipoDni).toPromise().then(rpGetDatosPaciente => {
     if (rpGetDatosPaciente) {
        if (!rpGetDatosPaciente.fechaNacimiento) {
          this.datosPaciente.edad = "";
        }
        this.datosPaciente = rpGetDatosPaciente;
      }
      this.cargando = false;
    }, error => {
      console.log(error)
    })
  }
  get f() {
    return this.registerForm.controls;
  }

  async onSubmit() {
    this.submitted = true;
    this.alertService.clear();
    if (this.registerForm.invalid) {
      return;
    }
    this.loading = true;
    let registrado=false;
    await this.pacienteService
      .crearPaciente(
        this.registerForm.value.firstName,
        this.registerForm.value.lastName,
        this.registerForm.value.gender,
        moment(this.registerForm.value.date).format('DD/MM/YYYY'),
        this.registerForm.value.mail,
        this.registerForm.value.tel,
        this.registerForm.value.location,
        this.registerForm.value.city,
        this.registerForm.value.cod,
       (this.registerForm.value.tipoDocumento)? this.registerForm.value.tipoDocumento: null,
       (this.registerForm.value.nroDocumento) ?  this.registerForm.value.nroDocumento: null,
      )
      .toPromise().then(
        (data) => {      
          if (data.ok) {
               sessionStorage.setItem('codigoTemporal', data.numeroDocumento);
            if(this.registerForm.value.tipoDocumento){
              sessionStorage.setItem('tipoDoc',this.registerForm.value.tipoDocumento);
              sessionStorage.setItem('nroDoc',this.registerForm.value.nroDocumento);
            }
           if(this.registerForm.value.tipoDocumento && this.registerForm.value.nroDocumento){
                //Fusionar paciente del turno con el recien creado y anunciar llegada
               registrado=true;
               sessionStorage.setItem('nroDoc', this.registerForm.value.nroDocumento);
              
            }
            this.dialogRef.close(data);
          } else {
            this.alertService.error(data.mensaje);
          }
          this.loading = false;
          this.registerForm.reset();
        },
        (error) => {
          this.alertService.error('Se genero un error');
          console.log(error);
          this.loading = false;
          this.registerForm.reset();
        }
      );
  }
}